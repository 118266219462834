const firebaseConfig = {

    apiKey: "AIzaSyDXxqp2PT605VxSsFBqTp9wp5XIRHZj_XI",

    authDomain: "attrabit-bbc5b.firebaseapp.com",

    projectId: "attrabit-bbc5b",

    storageBucket: "attrabit-bbc5b.appspot.com",

    messagingSenderId: "111322376540",

    appId: "1:111322376540:web:a59288bea3dff4cb1c3999",

    measurementId: "G-FCS8HZ7E01"

};

export default firebaseConfig;